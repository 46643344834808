import React from "react";
import { ReactComponent as PredictAnalyseLoader } from "../assets/images/nav-bar-icon.svg";

function Loader() {
  return (
    <div className="loader-logo">
      <PredictAnalyseLoader />
    </div>
  );
}
export default Loader;
