import React, { lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import "./App.css";
import ScrollToTop from "./hooks/ScrollToTop";
import Loader from "./components/Loader";
import GA4React from "ga-4-react";

try {
  setTimeout((_) => {
    const ga4react = new GA4React("G-WB0DDJP49Z");
    ga4react.initialize().catch((err) => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}

const Navbar = lazy(() => import("./layout/Navbar"));
const Footer = lazy(() => import("./layout/Footer"));
const Home = lazy(() => import("./pages/home/Home"));
const RiskAnalysis = lazy(() => import("./pages/risk analysis/RiskAnalysis"));
const PredictionHealthFactors = lazy(() =>
  import("./pages/prediction health factors/PredictionHealthFactors")
);
const AutomatedCoaching = lazy(() =>
  import("./pages/automated coaching/AutomatedCoaching")
);
const Contact = lazy(() => import("./pages/contact/Contact"));
const Formations = lazy(() => import("./pages/formations/Formations"));
const AboutUs = lazy(() => import("./pages/about us/AboutUs"));
const DownloadFirstGuide = lazy(() =>
  import("./pages/download guide/DownloadFirstGuide")
);
const DownloadSecondGuide = lazy(() =>
  import("./pages/download guide/DownloadSecondGuide")
);
const FormationGeste = lazy(() =>
  import("./pages/formations/pages/FormationGeste")
);
const FormationTms = lazy(() =>
  import("./pages/formations/pages/FormationTms")
);
const FormationNeuroscience = lazy(() =>
  import("./pages/formations/pages/FormationNeuroscience")
);
const LivresBlocs = lazy(() => import("./pages/livres blocs/LivresBlocs"));
const ScientificLiterature = lazy(() =>
  import("./pages/scientific literature/ScientificLiterature")
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <>
          <Router>
            <React.Suspense fallback={<Loader />}>
              <ScrollToTop />
              <Navbar />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/produit/analyse-des-risques"
                  component={RiskAnalysis}
                />
                <Route
                  exact
                  path="/produit/prediction-des-facteurs-de-santé"
                  component={PredictionHealthFactors}
                />
                <Route
                  exact
                  path="/produit/accompagnement-automatisé"
                  component={AutomatedCoaching}
                />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/formations" component={Formations} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route
                  exact
                  path="/télécharger-premier-guide"
                  component={DownloadFirstGuide}
                />
                <Route
                  exact
                  path="/télécharger-deuxième-guide"
                  component={DownloadSecondGuide}
                />
                <Route
                  exact
                  path="/formations/tms"
                  component={FormationGeste}
                />
                <Route
                  exact
                  path="/formations/geste"
                  component={FormationTms}
                />
                <Route
                  exact
                  path="/formations/neuroscience"
                  component={FormationNeuroscience}
                />
                <Route
                  exact
                  path="/ressources/livres-blancs"
                  component={LivresBlocs}
                />
                <Route
                  exact
                  path="/ressources/littérature-scientifique"
                  component={ScientificLiterature}
                />
                <Redirect to="/" />
              </Switch>
              <Footer />
            </React.Suspense>
          </Router>
        </>

        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
    </>
  );
}

export default App;
